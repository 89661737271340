$newsHeight: 125px; // 新着情報欄の高さ
$sectionWidth: 90%; // sectionの幅を指定
/*
* Description: サービスページ用CSS
*/

* {
  box-sizing: border-box;
}

html {
  overflow-y: scroll;
}

#container,
body,
html {
  font-family: 'Noto Sans Japanese';
  height: 100%;
}


#service {
  // ページ上部固定ロゴ
  header {
    position: fixed;
    z-index: 100;
  }

  .servicelogo {
    background-image: url("../image/logo_lookrec.png");
    width: 210px;
    height: 80px;
    background-position: 10px 10px;
    background-repeat: no-repeat;
    background-size: 200px;
    transition: all 0.1s ease-out;

    &.invert {
      background-image: url("../image/logo_lookrecblack.png");
      width: 130px;
      height: 60px;
      background-size: 120px;
      transition: all 0.1s ease-out;
    }
    @include mq {
      display: none;
    }
  }
  // サイドナビ
  nav.sidenav {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 99;
    @include mq {
      display: none;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      &:before {
        border-right: 2px solid #fff;
        height: 100%;
        content: "";
        position: absolute;
        right: 50%;
        top: 0;
      }

      &.border_change {
        &:before {
          border-right: 2px solid #d4d4d4;
          transition: all 0.5s linear;
        }
      }

      li {
        margin: 0;
        position: relative;

        .current {
          background-color: #52728a;
          color: #fff;

          &:hover {
            background-color: #3c566a;
          }
        }

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      a {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        display: table-cell;
        vertical-align: middle;
        font-size: 0.8em;
        font-weight: normal;
        line-height: 20px;
        color: #000;
        text-decoration: none;
        background-color: #fff;
        text-align: center;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        border: 1px solid #ccc;
        transition: all 0.2s linear;

        &:hover {
          background-color: #878787;
          color: #fff;
          box-shadow: 0 0 10px rgba(0,0,0,0.3);
          -moz-box-shadow: 0 0 10px rgba(0,0,0,0.3);
          -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.3);
        }
      }
    }
  }
  // メインビジュアル(PC:動画 SP:画像)
  .player-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -100;

    #player {
      @include mq {
        display: none;
      }
    }
  }

  #main-visual {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: url("../image/bg_obliqueline.png");
    background-repeat: repeat;
    @include mq {
      background-image: url("../image/bg_mvdummy.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .contents {
      width: 100%;
      color: #fff;
      position: relative;
      text-align: center;

      h1.lookrec_logo {
        background-image: url("../image/logo_lookrec.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: inline-block;
        width: 350px;
        height: 150px;
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        margin: 0;
        @include mq {
          width: 70vw;
          height: 15vh;
        }
      }

      p {
        margin: 0;
        padding-bottom: 50px;
        font-size: 2.5em;
        font-weight: lighter;
        text-align: center;
        line-height: 1.2em;
        @include mq {
          font-size: 5.5vw;
          padding-bottom: 30px;
        }
      }
      // ログイン・新規登録ボタン周り
      .login {
        display: inline-block;
        margin: 0 auto;
        text-align: center;
        position: relative;
        @include mq {
          width: 90%;
        }

        a {
          text-decoration: none;
          color: #fff;
        }

        .btn {
          position: relative;
          display: inline-block;
          padding: 15px 200px;
          width: 100%;
          height: 100%;
          @include mq {
            width: 100%;
            padding: 3% 5%;
            font-size: 4vw;
            margin-bottom: 20px;
          }


          &:after {
            font-family: 'FontAwesome';
            content: "\f054";
            position: absolute;
            line-height: 0;
            right: 10px;
            top: 50%;
            font-size: 1em;
          }
        }

        a.sign_up {
          position: absolute;
          right: 0;
          top: 70px;
          padding: 0 5px;
          @include mq {
            position: static;
            font-size: 4vw;
          }

          &:after {
            font-family: 'FontAwesome';
            content: "\f054";
            font-size: 10px;
            padding-left: 5px;
            position: relative;
            right: -2px;
            top: -1px;
            @include mq {
              font-size: 2vw;
            }
          }
        }
      }
    }
  }
  // ページ全体共通スタイル
  h2 {
    text-align: center;
    font-size: 1.2em;
    color: #3f3f3f;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 25px 0;
    margin-top: 0;
    margin-bottom: 0;
    @include mq {
      width: 100%;
      display: inline-block;
      font-size: 5vw;
    }
  }

  section {
    &:not(#news):not(.feature-slide) {
      width: $sectionWidth;
      margin: 0 auto 40px;
      @include mq {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    h3 {
      text-align: center;
      font-size: 2.8vw;
      font-weight: lighter;
      color: #3d6891;
      margin: 0;
      padding: 70px 0;
      @include mq {
        font-size: 8vw;
        line-height: 10vw;
        padding: 20px 0;
      }
    }

    h4 {
      color: #545454;
      font-size: 1.2em;
      line-height: 1.8em;
      padding-bottom: 20px;
      margin: 0;
      @include mq {
        font-size: 5vw;
        padding-bottom: 0;
      }
    }

    p {
      color: #545454;
      line-height: 2.3em;
      margin: 0;
      padding: 20px 0;
      font-feature-settings: "palt" 1;
      @include mq {
        font-size: 4.5vw;
        line-height: 1.8em;
      }
    }
  }
  // 新着情報ここから
  #news {
    background-color: #dadada;
    width: 100%;
    height: $newsHeight;

    &:after {
      display: block;
      clear: both;
      content: "";
    }

    .new_info {
      float: left;
      width: 20%;
      @include mq {
        float: none;
        width: 100%;
      }

      h2 {
        font-size: 1.2em;
        display: table-cell;
        vertical-align: middle;
        background-color: #ccc;
        border: none;
        width: 20%;
        height: $newsHeight;
        padding: 0;
        margin: 0;
        @include mq {
          width: 100%;
          display: block;
          height: 100%;
          padding: 10px 0;
          font-size: 5vw;
        }
      }
    }
    // 新着情報内容ここから
    .info_contents {
      background-color: #dadada;
      height: $newsHeight;
      float: left;
      width: 80%;
      padding: 0;
      margin: 0;
      overflow-y: scroll;
      @include mq {
        width: 100%;
        height: auto;
        overflow-y: auto;
      }

      ul {
        padding: 18px;
        margin: 0;
        list-style: none;

        li {
          margin-bottom: 11px;
        }
      }

      span {
        float: left;
        padding-right: 20px;
        color: #2e2e2e;
        @include mq {
          float: none;
          line-height: 1.5em;
        }
      }

      p {
        overflow: auto;
        margin: 0;
        padding: 0;
        line-height: normal;
        @include mq {
          font-size: 4vw;
        }

        a {
          color: #2e2e2e;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  // LOOKRECの利用イメージここから
  .service {
    &:after {
      display: block;
      clear: both;
      content: "";
    }
    // 利用イメージ背景画像
    &.image {
      margin-top: auto;
      height: 100%;
      background-image: url("../image/img_cloud-service.png");
      background-repeat: no-repeat;
      background-position: center bottom 5px;
      background-size: auto auto;
      padding-bottom: 250px;
      @include mq {
        background-size: 90%;
        padding-bottom: 0;
      }
      // 利用イメージテキストエリア
      .cloud_service {
        display: block;
        width: 45%;
        padding-left: 80px;
        margin: 0 0 auto;
        @include mq {
          width: 100%;
          padding: 0;
          margin: 0 0 160px;
        }

        h3 {
          display: block;
          text-align: center;
        }

        p {
          width: 100%;
          height: auto;
          padding-top: 0;
          margin: 0;
        }
      }
    }
  }
  // LOOKRECの機能ここから
  // LOOKRECの機能　背景
  .feature-slide {
    height: 100%;
    background-image: url("../image/service/bg_functionarea.png");
    background-repeat: repeat-x;
    background-position: center bottom;
    @include mq {
      background-image: none;
    }
  }
  // LOOKRECの機能スライドエリア
  .feature {
    width: 100%;
    padding-left: 12%;
    padding-right: 12%;
    @include mq {
      padding: 0 10px;
    }

    .feature-slide {
      height: 100%;
    }

    .slide_contents {
      width: 90%;
      margin: 0 auto;
      @include mq {
        width: 100%;
      }
    }

    .slide-left,
    .slide-right {
      width: 49%;
      display: inline-block;
      // float: left;
      margin: 0 auto;
      @include mq {
        // float: none;
        width: 100%;
      }
    }

    .slide-left {
      padding-right: 20px;
      vertical-align: top;
      @include mq {
        padding: 0;
      }
    }

    .slide-right {
      vertical-align: bottom;
      line-height: 1px;
      @include mq {
        width: 100%;
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        @include mq {
          width: 100%;
          height: 100%;
        }
      }
      // LOOKRECの機能(インターフェース画像下グラデーション)
      &:after {
        display: block;
        content: "";
        width: 100%;
        height: 35px;
        margin: auto;
        @include mq {
          width: 100%;
        }
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+20,000000+35,3a3a3a+53,000000+70&0.24+0,0+100 */
        background: -moz-linear-gradient(top, rgba(0,0,0,0.24) 0%, rgba(0,0,0,0.19) 20%, rgba(0,0,0,0.15) 35%, rgba(58,58,58,0.11) 53%, rgba(0,0,0,0.07) 70%, rgba(0,0,0,0) 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0,0,0,0.24) 0%,rgba(0,0,0,0.19) 20%,rgba(0,0,0,0.15) 35%,rgba(58,58,58,0.11) 53%,rgba(0,0,0,0.07) 70%,rgba(0,0,0,0) 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0,0,0,0.24) 0%,rgba(0,0,0,0.19) 20%,rgba(0,0,0,0.15) 35%,rgba(58,58,58,0.11) 53%,rgba(0,0,0,0.07) 70%,rgba(0,0,0,0) 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#3d000000', endColorstr='#00000000',GradientType=0 );
        /* IE6-9 */
      }
    }

    h3 {
      text-align: center;

      br {
        @include mq {
          display: none;
        }
      }
    }

    img {
      z-index: 1;
      @include mq {
        margin: 0 auto;
        width: 100%;
        height: 100%;
      }
    }

    .first-contents,
    .second-contents,
    .third-contents {
      &:after {
        display: block;
        clear: both;
        content: "";
      }
    }
  }
  // LOOKRECの利用費用ここから
  .price {
    h3 {
      br {
        @include mq {
          display: none;
        }
      }
    }
    // LOOKRECの利用費用(Google Platform画像)
    .platform_logo {
      float: left;
      width: 50%;
      text-align: center;
      @include mq {
        float: none;
        width: 100%;
        margin-bottom: 30px;
      }

      img {
        @include mq {
          width: 80%;
          height: 80%;
        }
      }
    }
    // LOOKRECの利用費用(料金詳細)
    .price_detail {
      float: left;
      width: 40%;
      @include mq {
        float: none;
        width: 100%;
      }

      h4 {
        color: #545454;
        padding: 0 0 20px;
      }
      // LOOKRECの利用費用(料金表)
      table {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        margin: 0;
        font-weight: bold;
        border-collapse: separate;
        border-spacing: 10px;
        width: 100%;
        @include mq {
          font-size: 4vw;
        }

        tr:first-child {
          border-bottom: 1px solid #dcdcdc;
        }

        td,
        th {
          padding: 10px;
        }

        th {
          background-color: #efefef;
          width: 30%;
          text-align: center;
          color: #545454;
        }

        td {
          color: #3e3e3e;
          background-color: #fff;
          width: 70%;
        }
      }

      ul {
        padding: 0;
        // LOOKRECの利用費用(料金表下注意書き)
        &.asterisk li {
          list-style-type: none;
          @include mq {
            font-size: 4vw;
          }

          &:before {
            display: inline;
            content: "※";
          }
        }
      }
    }
  }
  // サービスに関するお問合わせここから
  .contact {
    text-align: center;
    padding-bottom: 50px;
    @include mq {
      padding: 0;
    }

    p {
      display: inline-block;
      text-align: left;
      color: #545454;
    }
    // サービスに関するお問合わせテキストエリア
    .contact_guide {
      @include mq {
        padding: 20px 0;
      }

      br {
        @include mq {
          display: none;
        }
      }
    }
    // サービスに関するお問合わせ(表)
    .contact_detail {
      display: table;
      width: 80%;
      height: 150px;
      background-color: #f4f4f4;
      border: 1px solid #d2d2d2;
      margin: 0 auto;
      @include mq {
        width: 100%;
        height: 100%;
      }

      .contact_btn,
      .contact_info {
        width: 50%;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        @include mq {
          display: block;
          width: 100%;
        }
      }
    }
    // サービスに関するお問合わせ(電話番号)
    .contact_info {
      border-right: 1px solid #d2d2d2;
      @include mq {
        border-right: none;
        border-bottom: 1px solid #d2d2d2;
        text-align: center;
      }

      p {
        line-height: 1.2;
        padding: 0;

        a[href^="tel:"] {
          cursor: default;
        }
        @include mq {
          font-size: 4vw;
          margin: 20px auto;
          line-height: 1.2;
        }
      }

      a {
        font-size: 2.4em;
        font-weight: bold;
        color: #272727;
        text-decoration: none;
        @include mq {
          font-size: 10vw;
        }
      }
    }
    // サービスに関するお問合わせ(フォームボタン)
    .contact_btn {
      @include mq {
        margin: 20px 0;
      }
    }

    .btn {
      display: inline-block;
      // vertical-align: middle;
      padding: 15px 70px;
      @include mq {
        font-size: 4vw;
        padding: 5% 10%;
      }

      &:before {
        font-family: 'FontAwesome';
        content: "\f003";
        font-size: 1em;
        font-weight: normal;
        display: inline-block;
        color: #fff;
        vertical-align: middle;
        margin-right: 10px;
        position: relative;
        top: -2px;
      }
    }
  }
  // ページトップへ戻るボタン
  #page-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 40px;
    width: 34px;
    height: 37px;
    color: #666;

    .fa {
      position: absolute;
      @include mq {
        position: static;
      }
    }

    &:hover {
      text-decoration: none;
      color: #999;
      transition: all 0.3s linear;
      @include mq {
        color: #fff;
      }
    }
    @include mq {
      position: static;
      font-size: 4vw;
      text-decoration: none;
      color: #fff;
      background-color: #666;
      display: block;
      width: 90%;
      height: auto;
      margin: 0 auto 10px;
      text-align: center;
      border-radius: 5px;
      padding: 5px 0;

      &:after {
        content: "トップへ戻る";
      }
    }
  }
  // footerここから
  footer {
    border-top: 1px solid #eee;
    padding: 50px 0 30px;
    text-align: center;
    @include mq {
      font-size: 4vw;
    }

    address {
      font-style: normal;
      margin-bottom: 20px;
    }

    small {
      color: #a0a0a0;
    }
  }
}
