@charset "utf-8";
/*
Description: サービスページ用CSS
*/

@import "util/variables"; //変数
@import "util/mediaquery"; //mixin
@import "util/fontawesome/font-awesome.css"; //アイコンフォント

@import "base/normalize"; // ノーマライズCSS
@import "util/slick/slick"; // slick jquery plugin用CSS
@import "util/slick/slick-theme";
@import "util/slick/slick-theme_custom";
@import "page/service-notosans"; // notosans日本語フォント（サブセット版）


// 各ページ用css
@import "page/top"; // トップページ用css
@import "page/agree"; // 同意ページ用css
@import "page/regist"; // 利用者情報の登録ページ用css
@import "page/terms"; // ご利用規約css
@import "page/lower"; // 下層共通css

//module群
@import "module/all";




// ボタン周り統一スタイル
@mixin button-variant($color, $background) {
  color: $color;
  background-color: $background;

  &:hover {
    background-color: darken($background, 10%);
    box-shadow: 0 0 50px -9px rgba(0,0,0,0.58);
    -moz-box-shadow: 0 0 50px -9px rgba(0,0,0,0.58);
    -webkit-box-shadow: 0 0 50px -9px rgba(0,0,0,0.58);
    @include mq {
      background-color: $background;
      box-shadow: none;
    }

  }
}
// クラス名「btn-go」についての共通スタイル
.btn-go {
  @include button-variant($color, $btn-go-bg);
}

// クラス名「btn-cancel」についての共通スタイル
.btn-cancel {
  @include button-variant($color, $btn-cancel-bg);
}

// buttonタグリセット用
button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        appearance: none;
}
// クラス名「btn」についての共通スタイル
.btn {
  border-radius: 8px;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-out;
  @include mq {
    font-size: 5vw;
  }
}

// クラス名「sign_up」についての共通スタイル
.sign_up{
  border-bottom: 1px solid transparent;
  &:hover{
    border-bottom: 1px solid #fff;
    transition: border 0.2s linear;
    text-shadow: 0 0 50px -9px rgba(0,0,0,0.58);
    -moz-text-shadow: 0 0 50px -9px rgba(0,0,0,0.58);
    -webkit-text-shadow: 0 0 50px -9px rgba(0,0,0,0.58);
    @include mq {
      border: none;
    }
  }
}

// // [必須]ラベル共通スタイル
.required {
  background-color: #f04343;
  color: #fff;
  font-size: 0.7em;
  font-weight: 500;
  padding: 2px;
  display: inline-block;
  border-radius: 5px;
  margin: 0;
}

// ログイン、同意、登録ページ共通
#login,#agree,#regist,.lower{
  background-color: #759aab;
  background-image: url("../image/bg_obliqueline.png");
  background-repeat: repeat;
  color: #fff;
  @include mq {
    width: 100%;
  }
}

.taC{
  text-align: center;
}
.taL{
  text-align: left;
}
.taR{
  text-align: right;
}
