$h-color: #e9e9e9;


  header{
    display: block;
    height: 100px;
    @include mq {
      text-align: center;
    }
    #login &{
    @include mq {
        display: none;
      }
    }
    a{
      color: $h-color;
      text-decoration: none;
      transition: all 0.2s ease-out;
      &:hover{
        color: #fff;
        text-decoration: underline;
        transition: all 0.2s ease-out;
      }
    }
    img{
      display: inline-block;
      width: 360px * .5;
      height: 100px * .5;
      margin-top: 20px;
      .lower &{
        margin-left: 0;
      }
    }
    #global-nav {
      display: block;
      position: relative;
      @include mq {
        display: none;
      }
      html[lang="ja"] &{
        .lang_ja{
          a{
            color: #fff;
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
      html[lang="en"] &{
        .lang_en{
          a{
            color: #fff;
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
      // 現在のページのliのaにクラスを付与
      .current {
        color: #fff;
        font-weight: 600;
        text-decoration: underline;
      }
      ul{
        @extend .clearfix;
        li{
          margin: 0 5px;
          list-style: none;
          float: left;
        }
        &.lang{
          position: absolute;
          right: 5px;
          top: -85px;
          display: none;
          font-size: .8em;
          #login &{
            display: block;
          }
        }
        &.nav{
          font-size: .9em;
          position: absolute;
          right: 0;
          top: -50px;
          .lower &{
            right: 0;
          }
          li{
            margin-left: 10px;
            &:not(:last-child){
              margin-right: 10px;
            }
          }
          // ナビの運営会社のご案内にのみアイコンつける
          .company{
            a{
              &:after{
                font-family: 'FontAwesome';
                content: "\f08e";
                display: inline-block;
                line-height: 0;
                margin-left: 3px;
                position: relative;
                font-weight: normal;
              }
            }
          }
        }
      }
    }
  }
