/*
* Description: 利用者情報の登録ページ用CSS
*/
#regist {
  #container {
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
      margin: 15px 0 30px;
      font-size: 2em;
      font-weight: 400;
      @include mq {
        font-size: 6vw;
        font-weight: bold;
        margin: 20px 0;
      }
    }

    section {
      display: flex;
      align-items: center;
      flex-grow: 1;
      width: 100%;
      @include mq {
        display: block;
        height: auto;
      }
    }

    .contents_area {
      position: relative;
      // max-width: 750px;
      // width: 50%;
      margin: 0 auto;
      @include mq {
        width: 100%;
      }

      p {
        text-align: center;
        margin: 0;
        @include mq {
          font-size: 4vw;
        }
      }
      // フォーム部分
      .input_area {
        display: table;
        width: 100%;
        color: #000;
        background-color: #fff;
        padding: 30px 0;
        border-radius: 10px;
        margin: 40px auto;
        @include mq {
          padding: 20px 0 30px;
          border-radius: 3px;
          margin: 20px 0;
        }

        dl {
          display: table-row;
          @include mq {
            display: block;
          }

          &:not(:last-child) {
            height: 50px;
            @include mq {
              height: auto;
            }
          }
        }

        dd,
        dt {
          display: table-cell;
          @include mq {
            display: block;
          }
        }

        dt {
          width: 50%;
          text-align: right;
          padding-right: 10px;
          @include mq {
            width: 100%;
            text-align: left;
            margin-bottom: 5px;
            padding-left: 15px;
          }
        }

        dd {
          position: relative;
          margin: 0;
          @include mq {
            padding-left: 10px;
          }

          input {
            width: 50%;
            max-width: 250px;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding-left: 5px;
            @include mq {
              width: 90%;
              max-width: 100%;
              height: 50px;
              font-size: 6vw;
              padding-left: 10px;
            }

            &::-webkit-input-placeholder {
              color: #ccc;
            }

            &::-moz-placeholder {
              color: #ccc;
              opacity: 1;
            }

            &:-ms-input-placeholder {
              color: #ccc;
            }

            &.readonly {
              border: 1px solid #555;
              background-color: #ccc;
              color: #555;
            }
          }
          // [必須]ラベル
          span {
            &.required {
              position: relative;
              right: -10px;
              @include mq {
                right: 0px;
              }
            }
          }
        }
      }
      // ページ下部ボタンエリア
      .btn_area {
        width: 100%;
        display: block;
        @include mq {
          width: 100%;
          margin-bottom: 20px;
        }

        &:after {
          display: block;
          content: "";
          clear: both;
        }
        // ボタン共通スタイル
        button {
          font-weight: bold;
          display: inline-block;
          text-decoration: none;
          padding: 10px 0;
          margin: 0;
          @include mq {
            font-size: 5vw;
          }
          // キャンセルボタン
          &#cancel {
            float: left;
            width: 35%;
            min-width: 120px;
            @include mq {
              float: none;
              width: 100%;
              margin: 20px auto 0 0;
            }
          }
          // 登録ボタン
          &#regist_btn {
            float: right;
            width: 60%;
            min-width: 120px;
            position: relative;
            @include mq {
              float: none;
              width: 100%;
              margin: 0;
            }
            // 矢印アイコンについて
            &:after {
              font-family: 'FontAwesome';
              content: "\f054";
              font-size: 1em;
              font-weight: normal;
              line-height: 0;
              position: absolute;
              right: 10px;
              top: 50%;
              @include mq {
                font-size: 4vw;
              }
            }
          }
        }
      }
    }
  }
}
