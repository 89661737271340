/*
* Description: 同意ページ用CSS
*/


#agree {
  h1 {
    text-align: center;
    margin: 15px 0;
    font-size: 2em;
    font-weight: 400;
    @include mq {
      font-size: 6vw;
      font-weight: bold;
    }
  }

  .contents_area {
    // border-top: 1px solid #fff;
    position: relative;
    // max-width: 750px;
    width: 80%;
    margin: 0 auto;
    @include mq {
      width: 100%;
    }
    // pタグ共通スタイル
    p {
      text-align: center;
      margin: 0;
      @include mq {
        font-size: 4vw;
        margin: 10px 0;
      }
    }
    // 利用規約文について
    .terms_of_service {
      position: relative;

      p {
        text-align: left;
        margin: 30px 0 40px;
        padding: 10px 20px 0;
        color: #000;
        background-color: #fff;
        background-image: none;
        border: 3px solid #666;
        height: 300px;
        overflow-y: auto;
        @include mq {
          height: 250px;
          font-size: 4vw;
          padding: 5px;
          margin-bottom: 30px;
          border: 1px solid #666;
          // overflow-scrolling: touch;
          &::-webkit-scrollbar {
            width: 10px;
          }

          &::-webkit-scrollbar-track {
            margin: 0 2px;
            background: #ccc;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #666;
          }
        }
        // ボーダー下三角
        &:before {
          content: "";
          position: absolute;
          bottom: -57px;
          right: 47%;
          border: 30px solid transparent;
          border-top: 30px solid #666;
          @include mq {
            display: none;
          }
        }
      }
    }
    // 同意しましたチェックエリア
    .checkbox_area {
      width: 100%;
      margin: 0 auto;
      border-radius: 10px;
      border: 2px solid rgba(240,67,67,0.8);
      background-color: #fff;
      color: #000;
      padding: 10px;
      @include mq {
        width: 100%;
        padding: 0;
        border-radius: 3px;
        border: 1px solid #f04343;
      }

      input[type='checkbox'],
      label {
        cursor: pointer;
      }

      label {
        display: block;
        padding: 10px;
        text-align: left;
        position: relative;
        @include mq {
          width: 100%;
          font-size: 4vw;
        }

        &:after {
          display: block;
          content: "";
          clear: both;
        }
      }

      input {
        float: left;
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: 6px;
        @include mq {
          position: static;
        }
      }

      span {
        &.agree_text {
          float: left;
          display: inline-block;
          width: 95%;
          @include mq {
            width: 85%;
          }
        }
        // [必須]ラベル
        &.required {
          position: absolute;
          right: 0;
          @include mq {
            right: 5px;
          }
        }
      }
    }
    // ページ下部ボタンエリア
    .btn_area {
      width: 100%;
      margin: 40px auto;
      display: block;
      text-align: center;
      position: relative;
      // padding-bottom: 20px;
      @include mq {
        width: 100%;
        margin: 30px auto;
      }

      &:after {
        display: block;
        content: "";
        clear: both;
      }
      // ボタン共通スタイル
      button {
        display: inline-block;
        text-decoration: none;
        padding: 10px 0;
        margin: 0;
        position: relative;
        @include mq {
          font-size: 5vw;
        }
        // キャンセルボタン
        &#cancel {
          float: left;
          width: 35%;
          @include mq {
            float: none;
            width: 100%;
            margin: 0 auto 0 0;
          }
        }
        // 次へボタン
        &#submit {
          float: right;
          width: 60%;
          @include mq {
            float: none;
            width: 100%;
            margin: 0 0 25px;
          }

          &:after {
            font-family: 'FontAwesome';
            content: "\f054";
            font-size: 1em;
            font-weight: normal;
            line-height: 0;
            position: absolute;
            right: 10px;
            top: 50%;
            @include mq {
              font-size: 4vw;
            }
          }
        }
      }
    }
  }
}
// 同意ページ、登録ページ共通

#agree,
#regist {
  section {
    border-top: 1px solid rgba(255,255,255,0.5);
    border-bottom: 1px solid rgba(255,255,255,0.5);
  }
  // ページ上部ロゴ
  img {
    // margin: 10px 0 10px 10px;
    // width: 150px;
    // vertical-align: bottom;
    @include mq {
      // margin: 10px 0 10px 10px;
      // width: 40vw;
    }
  }

  footer {
    padding: 30px 0;
    text-align: center;
    @include mq {
      font-size: 4.3vw;
    }
  }
}
