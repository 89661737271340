//メディアクエリ出力用mixin
// @mixinの定義
// Mediaquery
$breakpoint-max: ( 'sm': 'screen and (max-width: 600px)'); //スマホ境界

@mixin mq($breakpoint: sm) {
   @media #{map-get($breakpoint-max, $breakpoint)}{
    @content;
  }
}
