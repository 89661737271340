@charset "UTF-8";

.slick-next,
.slick-prev {
  font-size: 2em;
  transition: color 0.3s linear;
  height: 30px;
  width: 20px;

  &:before {
    font-family: 'FontAwesome';
    font-size: 1em;
    font-weight: lighter;
    line-height: 1;
    color: #00a0e9;
    opacity: 1;
    position: absolute;
    z-index: 50;
  }

  &:hover {
    &:before {
      // font-family: 'FontAwesome';
      // font-size: 1em;
      // font-weight: lighter;
      // line-height: 1;
      // color: #00a0e9;
      opacity: 0.7;
    }
  }
}

.slick-prev {
  left: 10%;

  [dir="rtl"] & {
    left: auto;
    right: 10%;
  }

  &:before {
    content: "\F053";

    [dir="rtl"] & {
      content: "\F054";
    }
  }
}

.slick-next {
  right: 10%;

  [dir="rtl"] & {
    left: 10%;
    right: auto;
  }

  &:before {
    content: "\F054";

    [dir="rtl"] & {
      content: "\F053";
    }
  }
}
// コンテンツ下のドットについて

.slick-dots {
  position: static;
  bottom: -35px;
  list-style: none;
  display: inline-block;
  padding: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 0;
    cursor: pointer;
    left: 0;
    right: 0;
    margin: auto 15px;

    &:after {
      font-size: 0.7em;
      font-weight: bold;
      height: 100%;
      margin: auto;
      display: inline;
    }

    &:first-child {
      &:after {
        content: "インターフェース";
      }
    }

    &:nth-child(2) {
      &:after {
        content: "２つ目のコンテンツ";
      }
    }

    &:nth-child(3) {
      &:after {
        content: "３つ目のコンテンツ";
      }
    }

    button {
      border: 0;
      background: transparent;
      display: block;
      outline: none;
      line-height: 0;
      color: transparent;
      padding: 0;
      cursor: pointer;
      margin: auto;

      &:focus,
      &:hover {
        outline: none;

        &:before {
          color: #00a0e9;
          opacity: 0.7;
        }
      }

      &:before {
        position: absolute;
        left: 0;
        right: 0;
        content: $slick-dot-character;
        width: 100%;
        height: 100%;
        font-family: $slick-font-family;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        color: #c9c9c9;
        opacity: 1;
      }
    }

    &.slick-active button:before {
      color: #00a0e9;
      opacity: 1;
    }
  }
}

.slick-list {
  .slick-loading & {
    background: #fff url("../image/service/ajax-loader.gif") center center no-repeat;
  }
}
/* Icons */

@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: url("../font/slick/slick.eot");
    src: url("../font/slick/slick.eot?#iefix") format("embedded-opentype"), url("../font/slick/slick.woff") format("woff"), url("../font/slick/slick.ttf") format("truetype"), url("../font/slick/slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}
