/*
* Description: 下層用CSS
*/
$containerwidth: 60%;
$h-color: #e9e9e9;


// ログインページのみ
#login {
  #container {
    width: $containerwidth;
    min-width: 900px;
    margin: 0 auto;
    height: auto;
    min-height: 100%;
    @include mq {
      width: 100%;
      min-width: auto;
    }

    section {
      position: relative;
      top: 10%;
      @include mq {
        position: static;
      }
    }
    h1{
      margin: 80px 0;
      @include mq {
        margin: 80px 0;
      }
    }

    .login_top {
      margin: 0 auto;
      text-align: center;
      @include mq {
        width: 90%;
      }

      img {
        @include mq {
          width: 360px * 0.8;
          height: 100px * 0.8;
        }
      }

      .btn_login {
        width: 70%;
        max-width: 450px;
        background-color: #fff;
        padding: 18px;
        border-radius: 10px;
        font-weight: 600;
        font-size: 1.7em;
        text-decoration: none;
        color: #58a4c9;
        display: block;
        margin: 100px auto 0;
        vertical-align: middle;
        box-shadow: 0 7px 18px -5px rgba(0,0,0,0.49);
        -moz-box-shadow: 0 7px 18px -5px rgba(0,0,0,0.49);
        -webkit-box-shadow: 0 7px 18px -5px rgba(0,0,0,0.49);
        transition: all 0.2s ease-out;
        @include mq {
          margin: 70px auto 0;
          width: 100%;
          padding: 10px;
        }
        &:hover {
          box-shadow: 0 0 22px 15px rgba(0,0,0,0.09);
          -moz-box-shadow: 0 0 22px 15px rgba(0,0,0,0.09);
          -webkit-box-shadow: 0 0 22px 15px rgba(0,0,0,0.09);
        }
      }

      .about {
        display: inline-block;
        margin: 20px 0 100px;
        font-weight: 600;
        font-size: 1.2em;
        color: #fff;
        transition: all 0.2s ease-out;
        &:hover {
          color: #96dcff;
          transition: all 0.2s ease-out;
        }
        @include mq {
          margin: 15px 0 80px;
        }
      }

        p {
          font-size: .9em;
          display: inline-block;
          text-align: left;
        }
    }
  }
}

// .lowerがついているページのみ
html.lower {
  h1, h2, h3 {
    font-weight: 400;
  }
  #container {
    width: $containerwidth;
    min-width: 900px;
    margin: 0 auto;
    @include mq {
      width: 90%;
      min-width: auto;
    }
    section {
      color: #000;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #aaa;
      padding: 20px 40px;
      counter-reset: cnt;
      @include mq {
        padding: 10px;
      }
      h1, h2 {
        border-bottom: 1px solid #aaa;
      }
      h1 {
        &:first-child {
          margin-top: 0;
        }
        @include mq {
          font-size: 6vw;
        }
      }
      h2{
        margin-top: 60px;
        @include mq {
          font-size: 5vw;
        }
      }
      h3 {
        margin-top: 40px;
        @include mq {
          font-size: 4.5vw;
        }
        &:before {
          content: "■";
          font-size: 0.9em;
          margin-right: 3px;
          font-weight: normal;
          line-height: 0;
        }
      }
      img{
        display: block;
        margin: 15px auto;
        @include mq {
          width: 100%;
        }
      }
      a {
        color: #0083FF;
      }
      p {
        @include mq {
          font-size: 4vw;
        }
      }
      ul{
        padding-left: 0;
        @include mq {
          padding-left: 20px;
        }
      }

      dd {
        margin-left: 0;
      }

      // 下層ページ共通
      .contents{
        color: #444;
        padding-left: 30px;
        padding-right: 30px;
        @include mq {
          padding: 0;
        }
      }
      .count {
        counter-increment: cnt;
        &:before {
          content: counter(cnt)".";
        }
      }
      // 個人情報保護方針ページ
      .info{
        margin-bottom: 15px;
        display: inline-block;
        padding: 20px;
        border: 1px solid #d9d9d9;
        @include mq {
          padding: 10px;
        }
        .businessday{
          font-size: .8em;
        }
        .phone{
          font-size: 1.5em;
        }
        .consultation{
          color: #0083FF;
        }
      }
  }
  }
}

// スマホ時　下部のナビ
.sp_navi {
  display: none;
  @include mq {
    display: block;
    margin: 30px auto;
    width: 100%;
    ul {
      padding: 0;
      #login &{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
      li{
        list-style: none;

      }
      // 日本語英語切り替え
    &.lang {
      text-align: right;
      margin: 0 auto;
      li{
        display: inline-block;
        margin: 5px;
      }
      a{
        color: #fff;
      }
      display: none;
      #login &{
        display: block;
      }
    }
    // 各ページへのリンク
    &.nav{
      background-color: #fff;
      li {
        border-bottom: 1px solid #a4a4a4;
      }
      a{
        display: block;
        padding: 20px 30px;
        color: #000;
        text-decoration: none;
        text-align: center;
        position: relative;
        &:last-child {
          border-bottom: 0;
        }
        &:after {
          font-family: 'FontAwesome';
          content: "\f054";
          color: #a4a4a4;
          line-height: 0;
          position: absolute;
          right: 10px;
          top: 50%;
          font-weight: normal;
        }
      }
      // 現在のページのliに付与される
      .current {
        background-color: #bfd5df;
        font-weight: 600;
        }
      }
    }
  }
}

// ログイン、下層共通
#login,.lower{
  footer {
    text-align: center;
    margin-top: 30px;
    width: 100%;
  }
}
